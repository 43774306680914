@import url(~bootstrap-css-only/css/bootstrap.min.css);
@import url(~c3/c3.min.css);
@import url("https://smc.org.in/fonts/meera.css");
body {
  /*  ANT DESIGN BACKTOP */
  /*  ANT DESIGN BADGE */
  /*  ANT DESIGN CALENDAR */
  /*  ANT DESIGN CASCADER */
  /*  ANT DESIGN DROPDOWN */
  /*  ANT DESIGN FORM */
  /*  ANT DESIGN INPUT */
  /*  ANT DESIGN MODAL */
  /*  ANT DESIGN SELECT */
  /*  ANT DESIGN SLIDER */
  /*  ANT DESIGN SPIN */
  /*  ANT DESIGN LAYOUT */
  /*  ANT DESIGN NOTIFICATION */
  /*  ANT DESIGN PROGRESS */
  /*  ANT DESIGN MENU */ }
  body .ant-back-top {
    right: 3.84rem;
    bottom: 3.84rem; }
  body .ant-back-top-content {
    border-radius: 5px;
    background: #d2d9e5; }
    body .ant-back-top-content:hover {
      background: #b8beca; }
  body .ant-badge-count {
    font-family: inherit; }
  body .ant-calendar {
    border: 1px solid #e9e9e9; }
  body .events {
    list-style: none;
    margin: 0;
    padding: 0; }
  body .events .ant-badge-status {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    font-size: 12px; }
  body .notes-month {
    text-align: center;
    font-size: 28px; }
  body .notes-month section {
    font-size: 28px; }
  body .ant-fullcalendar-month-select {
    margin-left: 5px; }
  body .ant-calendar-picker-container {
    -webkit-animation-duration: 0s !important;
            animation-duration: 0s !important; }
  body .ant-calendar-range .ant-calendar-input-wrap {
    height: 38px; }
  body .ant-cascader-menus {
    border: 1px solid #e9e9e9; }
  body .ant-dropdown-menu {
    border: 1px solid #e9e9e9; }
  body .ant-dropdown-menu-item,
  body .ant-dropdown-menu-submenu-title {
    font-size: 1rem;
    padding: 0.61rem 1.53rem; }
  body .ant-dropdown-menu-item > a,
  body .ant-dropdown-menu-submenu-title > a {
    padding: 0.61rem 1.53rem;
    margin: -0.62rem -1.54rem; }
  body .ant-dropdown-menu-item > a.btn,
  body .ant-dropdown-menu-submenu-title > a.btn {
    margin: -0.62rem 0; }
  body .ant-dropdown-menu-item-group-list {
    list-style: none;
    padding: 0; }
  body .ant-dropdown-menu-item-group-title {
    font-weight: bold;
    font-size: 1rem;
    padding: 0.92rem 1.53rem;
    color: #74708d; }
  body .ant-form input[type='file'] {
    min-height: 40px; }
  body .ant-form input.ant-input-sm[type='file'] {
    min-height: 48px; }
  body .ant-form input.ant-input-lg[type='file'] {
    min-height: 52px; }
  body .ant-form-item-label {
    white-space: normal; }
  body .ant-form-item > .ant-form-item,
  body .ant-form-item :not(.ant-form) > .ant-form-item {
    margin-top: -2px; }
  body form .ant-select:only-child,
  body form .ant-cascader-picker:only-child {
    display: inline-block; }
  body .ant-input-disabled {
    background: #f9fafc; }
  body .ant-modal-close {
    outline: none !important; }
  body .ant-select {
    font-family: 'Nunito Sans', sans-serif; }
  body .ant-select-dropdown {
    border: 1px solid #e9e9e9;
    -webkit-animation-duration: 0s !important;
            animation-duration: 0s !important; }
  body .ant-select-auto-complete ol,
  body .ant-select-auto-complete ul,
  body .ant-select-auto-complete dl {
    margin: 0; }
  body .ant-slider-with-marks {
    margin-bottom: 19px; }
  body .ant-spin-blur {
    -webkit-filter: none !important;
            filter: none !important; }
  body .ant-spin-nested-loading > div > .ant-spin {
    max-height: none; }
  body .ant-layout {
    min-height: 100vh;
    background: transparent; }
  body .ant-layout-header {
    padding: 0; }
  body .ant-layout-footer {
    padding: 0;
    background: transparent; }
  body .ant-collapse {
    background: transparent; }
  body .ant-layout-sider {
    transition: all 0.2s; }
  body .ant-notification {
    max-width: calc(100vw - 48px); }
  body .ant-progress-text {
    white-space: nowrap; }
  body .ant-menu-item {
    outline: none !important; }
  body .ant-menu-dark.ant-menu-submenu-popup .ant-menu-item-divider {
    opacity: 0.2; }

/*  CORE MODULE STYLES */
html {
  font-size: 13px;
  overflow-y: scroll; }
  @media (max-width: 767px) {
    html {
      font-size: 12px; } }

body {
  font-family: "Nunito Sans", sans-serif !important;
  color: #514d6a;
  background: #f2f4f8;
  line-height: 1.7; }
  @media (max-width: 767px) {
    body {
      font-size: 12px; } }

svg {
  vertical-align: baseline;
  overflow: auto; }

a {
  text-decoration: none;
  color: #74708d;
  transition: color 0.2s ease-in-out; }
  a:hover, a:active, a:focus {
    color: #08f;
    text-decoration: none; }

input {
  outline: none !important;
  font-family: "Nunito Sans", sans-serif !important;
  color: #514d6a; }

button,
input {
  box-shadow: none !important; }

input[type='text'],
input[type='password'],
input[type='email'],
textarea {
  -webkit-appearance: none !important;
          appearance: none !important; }

input:-webkit-autofill {
  box-shadow: 0 0 0 50px white inset !important; }

label {
  display: inline-block;
  margin-bottom: 0; }

code {
  color: #74708d;
  background: #f2f4f8;
  display: inline-block;
  padding: 0.15rem 0.38rem;
  border-radius: 3px; }

pre {
  color: #74708d;
  padding: 1.53rem;
  border-radius: 3px;
  border: 1px solid #e4e9f0;
  background: #f2f4f8; }

button,
input,
select,
textarea {
  font-family: inherit; }

/*  CORE COMPONENTS & UTILITIES */
.utils__visibilityHidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(100%);
          clip-path: inset(100%); }

.utils__content {
  padding: 2.3rem;
  max-width: 120rem;
  margin: 0 auto; }
  @media (max-width: 767px) {
    .utils__content {
      padding: 2.3rem 0.76rem !important;
      overflow-x: hidden; } }

.utils__title {
  font-size: 1.3rem;
  color: #0e0b20; }

.utils__titleDescription {
  color: #c0bdd0; }

.utils__scrollable {
  overflow: auto; }

.utils__example {
  padding: 1.92rem;
  border: 1px solid #e4e9f0; }
  .utils__example:before {
    content: 'EXAMPLE';
    display: block;
    margin-bottom: 1.15rem;
    color: #74708d; }

.utils__link--underlined {
  border-bottom: 1px solid rgba(57, 55, 73, 0.25); }
  .utils__link--underlined:hover {
    border-bottom-color: rgba(0, 136, 255, 0.2); }

.utils__link--blue {
  color: #08f; }
  .utils__link--blue:hover {
    color: #0072d6; }
  .utils__link--blue.link-underlined {
    border-bottom: 1px solid rgba(0, 136, 255, 0.25); }
    .utils__link--blue.link-underlined:hover {
      border-bottom-color: rgba(0, 136, 255, 0.2); }

.utils__iconsBlock {
  padding: 0 0 2.3rem;
  text-align: center; }
  .utils__iconsBlock p {
    height: 2.3rem; }
  .utils__iconsBlock li {
    text-align: center;
    display: inline-block;
    width: 3.07rem;
    height: 3.07rem;
    line-height: 3.07rem;
    font-size: 2.76rem;
    padding: 0.76rem 2.3rem;
    box-sizing: content-box;
    border-radius: 3px;
    background: #eef0f4;
    margin: 0.76rem; }
    .utils__iconsBlock li:hover {
      background: #222034;
      color: #fff; }
  .utils__iconsBlock .tooltip {
    font-size: 1.38rem; }

.utils__scrollTable {
  white-space: nowrap !important; }
  .utils__scrollTable .ant-table-body {
    overflow-x: auto !important; }

/*  DEFAULT MEASUREMENTS STYLES */
.font-size-0 {
  font-size: 0rem; }

.font-size-10 {
  font-size: 0.76rem; }

.font-size-12 {
  font-size: 0.92rem; }

.font-size-14 {
  font-size: 1.07rem; }

.font-size-16 {
  font-size: 1.23rem; }

.font-size-18 {
  font-size: 1.38rem; }

.font-size-20 {
  font-size: 1.53rem; }

.font-size-24 {
  font-size: 1.84rem; }

.font-size-26 {
  font-size: 2rem; }

.font-size-30 {
  font-size: 2.3rem; }

.font-size-36 {
  font-size: 2.76rem; }

.font-size-40 {
  font-size: 3.07rem; }

.font-size-50 {
  font-size: 3.84rem; }

.font-size-60 {
  font-size: 4.61rem; }

.font-size-70 {
  font-size: 5.38rem; }

.font-size-80 {
  font-size: 6.15rem; }

.height-100 {
  height: 7.69rem; }

.height-200 {
  height: 15.38rem; }

.height-300 {
  height: 23.07rem; }

.height-400 {
  height: 30.76rem; }

.height-500 {
  height: 38.46rem; }

.height-600 {
  height: 46.15rem; }

.height-700 {
  height: 53.84rem; }

.min-height-100 {
  min-height: 7.69rem; }

.min-height-200 {
  min-height: 15.38rem; }

.min-height-300 {
  min-height: 23.07rem; }

.min-height-400 {
  min-height: 30.76rem; }

.min-height-500 {
  min-height: 38.46rem; }

.min-height-600 {
  min-height: 46.15rem; }

.min-height-700 {
  min-height: 53.84rem; }

.width-50 {
  width: 3.84rem; }

.width-75 {
  width: 5.76rem; }

.width-100 {
  width: 7.69rem; }

.width-150 {
  width: 11.53rem; }

.width-200 {
  width: 15.38rem; }

.width-300 {
  width: 23.07rem; }

.width-400 {
  width: 30.76rem; }

.width-500 {
  width: 38.46rem; }

.width-600 {
  width: 46.15rem; }

.width-700 {
  width: 53.84rem; }

.width-30p {
  width: 30%; }

.width-50p {
  width: 50%; }

.width-60p {
  width: 60%; }

.width-100p {
  width: 100%; }

.max-width-50 {
  max-width: 3.84rem; }

.max-width-75 {
  max-width: 5.76rem; }

.max-width-100 {
  max-width: 7.69rem; }

.max-width-200 {
  max-width: 15.38rem; }

.max-width-300 {
  max-width: 23.07rem; }

.max-width-400 {
  max-width: 30.76rem; }

.max-width-500 {
  max-width: 38.46rem; }

.max-width-600 {
  max-width: 46.15rem; }

.max-width-700 {
  max-width: 53.84rem; }

.max-width-30p {
  max-width: 30%; }

.max-width-50p {
  max-width: 50%; }

.max-width-60p {
  max-width: 60%; }

.max-width-100p {
  max-width: 100% !important; }

/*  THEME SETTINGS STYLES */
.settings__fixedWidth {
  max-width: 1420px;
  margin: 0 auto; }
  .settings__fixedWidth:not(.settings__menuTop) .utils__content {
    padding-right: 0; }
  .settings__fixedWidth:not(.settings__menuTop) div[class^='style-module__inner'] {
    margin-right: 0; }
  @media (max-width: 1445px) {
    .settings__fixedWidth:not(.settings__menuTop) .utils__content {
      padding-right: 2.3rem; }
    .settings__fixedWidth:not(.settings__menuTop) div[class^='style-module__inner'] {
      margin-right: 2.3rem; } }
  .settings__fixedWidth.settings__menuTop .utils__content {
    padding-left: 0;
    padding-right: 0; }
  .settings__fixedWidth.settings__menuTop div[class^='style-module__inner'] {
    margin: 0; }
  @media (max-width: 1445px) {
    .settings__fixedWidth.settings__menuTop .utils__content {
      padding-left: 2.3rem;
      padding-right: 2.3rem; }
    .settings__fixedWidth.settings__menuTop div[class^='style-module__inner'] {
      margin: 0 2.3rem; } }

.settings__squaredBorders .card {
  border-radius: 0; }

.settings__menuShadow .ant-layout-sider {
  position: relative;
  z-index: 1;
  box-shadow: 0 0 200px -20px rgba(57, 55, 73, 0.4); }

.settings__borderLess .card {
  border: none; }

.settings__borderLess .card-header {
  padding-top: 1.53rem;
  padding-bottom: 0;
  border-bottom: 0; }

.settings__borderLess .card-footer {
  padding-top: 0;
  border-top: 0; }

.settings__borderLess .utils__title {
  text-transform: uppercase;
  font-size: 1.23rem; }

/* GRID */
.row {
  margin-left: -1.16rem;
  margin-right: -1.16rem; }

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9 {
  padding-left: 1.15rem;
  padding-right: 1.15rem; }

.container-fluid {
  padding-left: 1.15rem;
  padding-right: 1.15rem; }

@media (max-width: 1599px) {
  .hidden-xxl-down {
    display: none; } }

/* CARDS */
.card,
.card-header,
.list-group-item,
.card-footer {
  border-color: #e4e9f0; }

.card-header,
.card-footer {
  padding-left: 2.3rem;
  padding-right: 2.3rem;
  background: #fff; }
  .card-header:last-child,
  .card-footer:last-child {
    border-radius: 0 0 calc(7px - 1px) calc(7px - 1px); }
  .card-header:first-child,
  .card-footer:first-child {
    border-radius: calc(7px - 1px) calc(7px - 1px) 0 0; }
  @media (max-width: 991px) {
    .card-header,
    .card-footer {
      padding-left: 1.53rem;
      padding-right: 1.53rem; } }

.card-body {
  flex: 1 1 auto;
  padding: 1.92rem 2.3rem; }
  @media (max-width: 991px) {
    .card-body {
      padding-left: 1.53rem;
      padding-right: 1.53rem; } }

.card {
  margin-bottom: 2.3rem;
  border-radius: 7px; }
  .card--withShadow {
    transition: box-shadow 0.1s ease-in-out; }
    .card--withShadow:hover {
      box-shadow: 0 0 2.25rem #e1e1e1; }
  .card--fullHeight {
    height: calc(100% - 2.3rem); }
  .utils__content > .card:last-child {
    margin-bottom: 0; }

.card--example {
  border: 1px solid #e4e9f0 !important; }
  .card--example .card-header > h5 {
    margin-bottom: 0; }

/* UTILITIES */
.text-muted {
  color: #c0bdd0 !important; }

.text-primary {
  color: #0190fe; }

.text-success {
  color: #46be8a; }

.text-info {
  color: #0887c9; }

.text-warning {
  color: #f39834; }

.text-danger {
  color: #fb434a; }

.text-default {
  color: #acb7bf; }

.text-black {
  color: #0e0b20; }

.bg-primary {
  background-color: #0190fe !important; }

.bg-success {
  background-color: #46be8a !important; }

.bg-info {
  background-color: #0887c9 !important; }

.bg-warning {
  background-color: #f39834 !important; }

.bg-danger {
  background-color: #fb434a !important; }

.bg-inverse {
  background-color: #222034 !important; }

.bg-faded {
  background-color: #f2f4f8 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-default {
  background-color: #acb7bf !important; }

.progress {
  background: #f2f4f8; }
  .progress-bar {
    height: 1.38rem; }

/* BUTTONS */
.btn-link {
  color: #74708d; }
  .btn-link:focus, .btn-link:hover {
    color: #615d7c;
    text-decoration: none; }

.btn {
  outline: none !important;
  box-shadow: none !important;
  transition: color 0.2s ease-in-out, background 0.2s ease-in-out, border 0.2s ease-in-out;
  padding: 0.61rem 1.23rem;
  line-height: 1.7; }
  .btn:hover, .btn:active {
    transition: color 0.1s ease-in-out, background 0.1s ease-in-out, border 0.1s ease-in-out; }
  .btn.btn-rounded {
    border-radius: 100px; }
  .btn.btn-squared {
    border-radius: 0; }
  .btn.btn-sm {
    padding: 0.3rem 0.84rem; }
  .btn.btn-lg {
    padding: 0.92rem 1.53rem; }
  .btn.btn-link {
    color: #74708d;
    border-color: transparent !important;
    background: none !important;
    text-decoration: none; }
    .btn.btn-link:hover, .btn.btn-link:active, .btn.btn-link:focus, .btn.btn-link.active {
      color: #08f; }
  .btn.btn-icon {
    padding: 0.76rem;
    line-height: 1rem;
    text-decoration: none !important; }
    .btn.btn-icon.btn-sm {
      padding: 0.53rem; }
    .btn.btn-icon.btn-lg {
      padding: 1.53rem; }

.btn-group-vertical > label {
  margin-bottom: 0; }

.btn-group-justified {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate; }
  .btn-group-justified > .btn-group {
    float: none;
    display: table-cell;
    width: 1%; }
    .btn-group-justified > .btn-group > .btn {
      width: 100%; }
  .btn-group-justified.btn-group-vertical {
    display: block; }
    .btn-group-justified.btn-group-vertical > .btn-group {
      display: block;
      width: 100%; }

.btn,
.show > .btn {
  color: #74708d;
  background-color: #fff;
  border-color: #d2d9e5; }
  .btn:hover, .btn:active,
  .show > .btn:hover,
  .show > .btn:active {
    background-color: #dbdee4;
    border-color: #dbdee4; }
  .btn:hover:active, .btn:focus, .btn.active,
  .show > .btn:hover:active,
  .show > .btn:focus,
  .show > .btn.active {
    background: #cfd3db;
    border-color: #cfd3db; }
  .btn.btn-default, .btn.btn-primary, .btn.btn-secondary, .btn.btn-success, .btn.btn-info, .btn.btn-warning, .btn.btn-danger,
  .show > .btn.btn-default,
  .show > .btn.btn-primary,
  .show > .btn.btn-secondary,
  .show > .btn.btn-success,
  .show > .btn.btn-info,
  .show > .btn.btn-warning,
  .show > .btn.btn-danger {
    color: #fff !important; }
  .btn.btn-default,
  .show > .btn.btn-default {
    background-color: #acb7bf;
    border-color: #acb7bf; }
    .btn.btn-default:hover, .btn.btn-default:active,
    .show > .btn.btn-default:hover,
    .show > .btn.btn-default:active {
      background-color: #bdc6cc;
      border-color: #bdc6cc; }
    .btn.btn-default:hover:active, .btn.btn-default:focus, .btn.btn-default.active,
    .show > .btn.btn-default:hover:active,
    .show > .btn.btn-default:focus,
    .show > .btn.btn-default.active {
      background: #9ba8b2;
      border-color: #9ba8b2; }
  .btn.btn-primary,
  .show > .btn.btn-primary {
    background-color: #0190fe;
    border-color: #0190fe; }
    .btn.btn-primary:hover, .btn.btn-primary:active,
    .show > .btn.btn-primary:hover,
    .show > .btn.btn-primary:active {
      background-color: #1f9dfe;
      border-color: #1f9dfe; }
    .btn.btn-primary:hover:active, .btn.btn-primary:focus, .btn.btn-primary.active,
    .show > .btn.btn-primary:hover:active,
    .show > .btn.btn-primary:focus,
    .show > .btn.btn-primary.active {
      background: #017fe0 !important;
      border-color: #017fe0 !important; }
  .btn.btn-secondary,
  .show > .btn.btn-secondary {
    background-color: #6a7a84;
    border-color: #6a7a84; }
    .btn.btn-secondary:hover, .btn.btn-secondary:active,
    .show > .btn.btn-secondary:hover,
    .show > .btn.btn-secondary:active {
      background-color: #798993;
      border-color: #798993; }
    .btn.btn-secondary:hover:active, .btn.btn-secondary:focus, .btn.btn-secondary.active,
    .show > .btn.btn-secondary:hover:active,
    .show > .btn.btn-secondary:focus,
    .show > .btn.btn-secondary.active {
      background: #5c6a73;
      border-color: #5c6a73; }
  .btn.btn-success,
  .show > .btn.btn-success {
    background-color: #46be8a;
    border-color: #46be8a; }
    .btn.btn-success:hover, .btn.btn-success:active,
    .show > .btn.btn-success:hover,
    .show > .btn.btn-success:active {
      background-color: #5dc698;
      border-color: #5dc698; }
    .btn.btn-success:hover:active, .btn.btn-success:focus, .btn.btn-success.active,
    .show > .btn.btn-success:hover:active,
    .show > .btn.btn-success:focus,
    .show > .btn.btn-success.active {
      background: #3caa7a;
      border-color: #3caa7a; }
  .btn.btn-info,
  .show > .btn.btn-info {
    background-color: #0887c9;
    border-color: #0887c9; }
    .btn.btn-info:hover, .btn.btn-info:active,
    .show > .btn.btn-info:hover,
    .show > .btn.btn-info:active {
      background-color: #099be6;
      border-color: #099be6; }
    .btn.btn-info:hover:active, .btn.btn-info:focus, .btn.btn-info.active,
    .show > .btn.btn-info:hover:active,
    .show > .btn.btn-info:focus,
    .show > .btn.btn-info.active {
      background: #0773ac;
      border-color: #0773ac; }
  .btn.btn-warning,
  .show > .btn.btn-warning {
    background-color: #f39834;
    border-color: #f39834; }
    .btn.btn-warning:hover, .btn.btn-warning:active,
    .show > .btn.btn-warning:hover,
    .show > .btn.btn-warning:active {
      background-color: #f5a751;
      border-color: #f5a751; }
    .btn.btn-warning:hover:active, .btn.btn-warning:focus, .btn.btn-warning.active,
    .show > .btn.btn-warning:hover:active,
    .show > .btn.btn-warning:focus,
    .show > .btn.btn-warning.active {
      background: #f18917;
      border-color: #f18917; }
  .btn.btn-danger,
  .show > .btn.btn-danger {
    background-color: #fb434a;
    border-color: #fb434a; }
    .btn.btn-danger:hover, .btn.btn-danger:active,
    .show > .btn.btn-danger:hover,
    .show > .btn.btn-danger:active {
      background-color: #fc6167;
      border-color: #fc6167; }
    .btn.btn-danger:hover:active, .btn.btn-danger:focus, .btn.btn-danger.active,
    .show > .btn.btn-danger:hover:active,
    .show > .btn.btn-danger:focus,
    .show > .btn.btn-danger.active {
      background: #fa252d;
      border-color: #fa252d; }
  .btn.btn-outline-default, .btn.btn-outline-primary, .btn.btn-outline-secondary, .btn.btn-outline-success, .btn.btn-outline-info, .btn.btn-outline-warning, .btn.btn-outline-danger,
  .show > .btn.btn-outline-default,
  .show > .btn.btn-outline-primary,
  .show > .btn.btn-outline-secondary,
  .show > .btn.btn-outline-success,
  .show > .btn.btn-outline-info,
  .show > .btn.btn-outline-warning,
  .show > .btn.btn-outline-danger {
    background-color: #fff; }
  .btn.btn-outline-default,
  .show > .btn.btn-outline-default {
    border-color: #acb7bf;
    color: #acb7bf; }
    .btn.btn-outline-default:hover, .btn.btn-outline-default:active,
    .show > .btn.btn-outline-default:hover,
    .show > .btn.btn-outline-default:active {
      background-color: #bdc6cc;
      border-color: #bdc6cc;
      color: #fff; }
    .btn.btn-outline-default:hover:active, .btn.btn-outline-default:focus, .btn.btn-outline-default.active,
    .show > .btn.btn-outline-default:hover:active,
    .show > .btn.btn-outline-default:focus,
    .show > .btn.btn-outline-default.active {
      background: #9ba8b2;
      border-color: #9ba8b2;
      color: #fff; }
  .btn.btn-outline-primary,
  .show > .btn.btn-outline-primary {
    border-color: #0190fe;
    color: #0190fe; }
    .btn.btn-outline-primary:hover, .btn.btn-outline-primary:active,
    .show > .btn.btn-outline-primary:hover,
    .show > .btn.btn-outline-primary:active {
      background-color: #1f9dfe;
      border-color: #1f9dfe;
      color: #fff; }
    .btn.btn-outline-primary:hover:active, .btn.btn-outline-primary:focus, .btn.btn-outline-primary.active,
    .show > .btn.btn-outline-primary:hover:active,
    .show > .btn.btn-outline-primary:focus,
    .show > .btn.btn-outline-primary.active {
      background: #017fe0;
      border-color: #017fe0;
      color: #fff; }
  .btn.btn-outline-secondary,
  .show > .btn.btn-outline-secondary {
    border-color: #6a7a84;
    color: #6a7a84; }
    .btn.btn-outline-secondary:hover, .btn.btn-outline-secondary:active,
    .show > .btn.btn-outline-secondary:hover,
    .show > .btn.btn-outline-secondary:active {
      background-color: #798993;
      border-color: #798993;
      color: #fff; }
    .btn.btn-outline-secondary:hover:active, .btn.btn-outline-secondary:focus, .btn.btn-outline-secondary.active,
    .show > .btn.btn-outline-secondary:hover:active,
    .show > .btn.btn-outline-secondary:focus,
    .show > .btn.btn-outline-secondary.active {
      background: #5c6a73;
      border-color: #5c6a73;
      color: #fff; }
  .btn.btn-outline-success,
  .show > .btn.btn-outline-success {
    border-color: #46be8a;
    color: #46be8a; }
    .btn.btn-outline-success:hover, .btn.btn-outline-success:active,
    .show > .btn.btn-outline-success:hover,
    .show > .btn.btn-outline-success:active {
      background-color: #5dc698;
      border-color: #5dc698;
      color: #fff; }
    .btn.btn-outline-success:hover:active, .btn.btn-outline-success:focus, .btn.btn-outline-success.active,
    .show > .btn.btn-outline-success:hover:active,
    .show > .btn.btn-outline-success:focus,
    .show > .btn.btn-outline-success.active {
      background: #3caa7a;
      border-color: #3caa7a;
      color: #fff; }
  .btn.btn-outline-info,
  .show > .btn.btn-outline-info {
    border-color: #0887c9;
    color: #0887c9; }
    .btn.btn-outline-info:hover, .btn.btn-outline-info:active,
    .show > .btn.btn-outline-info:hover,
    .show > .btn.btn-outline-info:active {
      background-color: #099be6;
      border-color: #099be6;
      color: #fff; }
    .btn.btn-outline-info:hover:active, .btn.btn-outline-info:focus, .btn.btn-outline-info.active,
    .show > .btn.btn-outline-info:hover:active,
    .show > .btn.btn-outline-info:focus,
    .show > .btn.btn-outline-info.active {
      background: #0773ac;
      border-color: #0773ac;
      color: #fff; }
  .btn.btn-outline-warning,
  .show > .btn.btn-outline-warning {
    border-color: #f39834;
    color: #f39834; }
    .btn.btn-outline-warning:hover, .btn.btn-outline-warning:active,
    .show > .btn.btn-outline-warning:hover,
    .show > .btn.btn-outline-warning:active {
      background-color: #f5a751;
      border-color: #f5a751;
      color: #fff; }
    .btn.btn-outline-warning:hover:active, .btn.btn-outline-warning:focus, .btn.btn-outline-warning.active,
    .show > .btn.btn-outline-warning:hover:active,
    .show > .btn.btn-outline-warning:focus,
    .show > .btn.btn-outline-warning.active {
      background: #f18917;
      border-color: #f18917;
      color: #fff; }
  .btn.btn-outline-danger,
  .show > .btn.btn-outline-danger {
    border-color: #fb434a;
    color: #fb434a; }
    .btn.btn-outline-danger:hover, .btn.btn-outline-danger:active,
    .show > .btn.btn-outline-danger:hover,
    .show > .btn.btn-outline-danger:active {
      background-color: #fc6167;
      border-color: #fc6167;
      color: #fff; }
    .btn.btn-outline-danger:hover:active, .btn.btn-outline-danger:focus, .btn.btn-outline-danger.active,
    .show > .btn.btn-outline-danger:hover:active,
    .show > .btn.btn-outline-danger:focus,
    .show > .btn.btn-outline-danger.active {
      background: #fa252d;
      border-color: #fa252d;
      color: #fff; }

/* TABLE */
.table {
  width: 100% !important; }
  .table thead th {
    border-bottom: 1px solid #e4e9f0;
    outline: none !important; }
    .table thead th:focus {
      background: #e4e9f0; }
  .table td,
  .table th {
    border-color: #e4e9f0; }
    .table td:focus,
    .table th:focus {
      background-color: #e4e9f0; }
  .table tbody tr:first-child td {
    border-top: none; }
  .table.table-hover tbody tr:hover {
    background: #f2f4f8; }
  .table tr.active,
  .table th.active {
    background: #f2f4f8; }
  .table .thead-inverse th {
    background: #222034; }
  .table .thead-default th {
    background: #f2f4f8; }
  .table.table-striped tbody tr:nth-of-type(odd) {
    background: #f2f4f8; }
  .table.table-vertical-middle td,
  .table.table-vertical-middle th {
    vertical-align: middle; }

.table-inverse {
  color: #b8beca; }
  .table-inverse thead th {
    border-bottom-color: #393749;
    color: #fff; }
  .table-inverse th,
  .table-inverse td {
    border-top-color: #393749; }
  .table-inverse th,
  .table-inverse td,
  .table-inverse thead th {
    background: #222034; }
    .table-inverse th:focus,
    .table-inverse td:focus,
    .table-inverse thead th:focus {
      background: #222034; }
  .table-inverse tr.active,
  .table-inverse th.active {
    background: #eef0f4; }

/* TYPOGRAPHY */
.mark,
mark {
  background: #f2a654;
  color: #fff; }

.blockquote {
  background: #f2f4f8;
  padding: 30px;
  border-left: 3px solid #d2d9e5;
  font-size: 1.15rem; }
  .blockquote-reverse {
    text-align: right;
    border-left: none;
    border-right: 3px solid #d2d9e5; }
  .blockquote-footer {
    margin-top: 1.53rem;
    color: #b8beca; }

/* BREADCRUMB */
.breadcrumb {
  background: #f2f4f8; }
  .breadcrumb--custom {
    display: inline-block;
    padding: 0;
    margin-bottom: 0;
    background: none; }
    .breadcrumb--custom li {
      padding: 0; }
      .breadcrumb--custom li:before {
        display: none !important; }
      .breadcrumb--custom li > a,
      .breadcrumb--custom li > span {
        display: inline-block;
        padding: 9px 30px;
        position: relative;
        background: #eef0f4;
        margin: 0 0.61rem 0.46rem;
        font-size: 13px; }
        .breadcrumb--custom li > a:before,
        .breadcrumb--custom li > span:before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: -10px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 39px 10px 0 0;
          border-color: transparent #eef0f4 transparent transparent;
          transition: all 0.2s ease-in-out; }
        .breadcrumb--custom li > a:after,
        .breadcrumb--custom li > span:after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          right: -10px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 0 39px 10px;
          border-color: transparent transparent transparent #eef0f4;
          transition: all 0.2s ease-in-out; }
      .breadcrumb--custom li > a {
        transition: all 0.2s ease-in-out; }
        .breadcrumb--custom li > a:hover, .breadcrumb--custom li > a.active {
          background: #08f;
          color: #fff; }
          .breadcrumb--custom li > a:hover:before, .breadcrumb--custom li > a.active:before {
            border-color: transparent #08f transparent transparent; }
          .breadcrumb--custom li > a:hover:after, .breadcrumb--custom li > a.active:after {
            border-color: transparent transparent transparent #08f; }
      .breadcrumb--custom li:first-child > a,
      .breadcrumb--custom li:first-child > span {
        margin-left: 0; }
        .breadcrumb--custom li:first-child > a:before,
        .breadcrumb--custom li:first-child > span:before {
          display: none; }
      .breadcrumb--custom li:last-child > a:after,
      .breadcrumb--custom li:last-child > span:after {
        display: none; }
      .breadcrumb--custom li:after {
        padding: 0;
        content: ''; }

/* FORMS */
input::-webkit-input-placeholder {
  color: #b8beca !important; }

input::-moz-placeholder {
  color: #b8beca !important; }

input:-moz-placeholder {
  color: #b8beca !important; }

input:-ms-input-placeholder {
  color: #b8beca !important; }

.form-actions {
  border-top: 1px solid #e4e9f0;
  padding-top: 1.53rem;
  margin: 1.53rem 0; }

.form-control {
  font-family: "Nunito Sans", sans-serif !important;
  padding: 0.84rem 1.23rem;
  border-color: #e4e9f0;
  color: #74708d; }
  .form-control.form-control-rounded {
    border-radius: 500px; }
  .form-control:focus {
    border-color: #0190fe; }
  .form-control.form-control-sm {
    padding: 0.53rem 0.61rem; }

.col-form-label {
  padding-top: 0.69rem;
  padding-bottom: 0.69rem; }

.form-control:disabled,
.form-control[readonly] {
  background-color: #eef0f4; }

.input-group-addon {
  border-color: #e4e9f0;
  background-color: #eef0f4; }

span.input-group-addon {
  outline: none !important; }

.form-group.has-danger .form-control {
  border-color: #fb434a; }

.form-group.has-success .form-control {
  border-color: #46be8a; }

.form-group.has-warning .form-control {
  border-color: #f39834; }

.form-group.has-focused .form-control {
  border-color: #0190fe; }

.form-input-icon {
  position: relative; }
  .form-input-icon > i {
    color: #b8beca;
    position: absolute;
    margin: 1rem 0.15rem 0.3rem 0.76rem;
    z-index: 3;
    width: 1.23rem;
    font-size: 1.23rem;
    text-align: center;
    left: 0; }
  .form-input-icon .form-control {
    padding-left: 2.61rem; }
  .form-input-icon.form-input-icon-right > i {
    left: auto;
    right: 0.23rem;
    margin: 1rem 0.76rem 0.3rem 0.15rem; }
  .form-input-icon.form-input-icon-right .form-control {
    padding-left: 1.23rem;
    padding-right: 2.61rem; }

/* DROPDOWNS */
.dropdown-menu {
  border-color: #d2d9e5;
  top: 115%;
  padding: 0.61rem 0; }
  .dropdown-menu .dropdown-icon {
    margin-right: 0.38rem; }
  .dropdown-menu .dropdown-divider {
    background-color: #e4e9f0; }
  .dropdown-menu .dropdown-header {
    padding: 0.53rem 1.15rem;
    color: #615d7c;
    font-size: 1rem;
    font-weight: 600; }
  .dropdown-menu .dropdown-item {
    color: #74708d;
    background: none;
    padding: 0.23rem 1.15rem;
    transition: color 0.2s ease-in-out; }
    .dropdown-menu .dropdown-item a {
      color: #74708d; }
      .dropdown-menu .dropdown-item a:hover, .dropdown-menu .dropdown-item a:active {
        background: none;
        color: #08f; }
    .dropdown-menu .dropdown-item.disabled {
      cursor: not-allowed;
      color: #e4e9f0 !important; }
      .dropdown-menu .dropdown-item.disabled a {
        color: #e4e9f0 !important;
        cursor: not-allowed; }
    .dropdown-menu .dropdown-item.active {
      background: #eef0f4 !important; }
    .dropdown-menu .dropdown-item:hover, .dropdown-menu .dropdown-item:active, .dropdown-menu .dropdown-item:focus {
      background: none;
      color: #08f; }

.dropdown-animate {
  overflow: hidden; }
  .dropdown-animate > .dropdown-item,
  .dropdown-animate .dropdown-header {
    -webkit-animation-name: dropdown-slide-left;
            animation-name: dropdown-slide-left;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both; }
    .dropdown-animate > .dropdown-item:nth-child(1),
    .dropdown-animate .dropdown-header:nth-child(1) {
      -webkit-animation-delay: 0.02s;
              animation-delay: 0.02s; }
    .dropdown-animate > .dropdown-item:nth-child(2),
    .dropdown-animate .dropdown-header:nth-child(2) {
      -webkit-animation-delay: 0.04s;
              animation-delay: 0.04s; }
    .dropdown-animate > .dropdown-item:nth-child(3),
    .dropdown-animate .dropdown-header:nth-child(3) {
      -webkit-animation-delay: 0.06s;
              animation-delay: 0.06s; }
    .dropdown-animate > .dropdown-item:nth-child(4),
    .dropdown-animate .dropdown-header:nth-child(4) {
      -webkit-animation-delay: 0.08s;
              animation-delay: 0.08s; }
    .dropdown-animate > .dropdown-item:nth-child(5),
    .dropdown-animate .dropdown-header:nth-child(5) {
      -webkit-animation-delay: 0.1s;
              animation-delay: 0.1s; }
    .dropdown-animate > .dropdown-item:nth-child(6),
    .dropdown-animate .dropdown-header:nth-child(6) {
      -webkit-animation-delay: 0.12s;
              animation-delay: 0.12s; }
    .dropdown-animate > .dropdown-item:nth-child(7),
    .dropdown-animate .dropdown-header:nth-child(7) {
      -webkit-animation-delay: 0.14s;
              animation-delay: 0.14s; }
    .dropdown-animate > .dropdown-item:nth-child(8),
    .dropdown-animate .dropdown-header:nth-child(8) {
      -webkit-animation-delay: 0.16s;
              animation-delay: 0.16s; }
    .dropdown-animate > .dropdown-item:nth-child(9),
    .dropdown-animate .dropdown-header:nth-child(9) {
      -webkit-animation-delay: 0.18s;
              animation-delay: 0.18s; }
    .dropdown-animate > .dropdown-item:nth-child(10),
    .dropdown-animate .dropdown-header:nth-child(10) {
      -webkit-animation-delay: 0.2s;
              animation-delay: 0.2s; }

.show > .dropdown-menu,
.dropdown-menu.open > .dropdown-menu {
  -webkit-animation-name: dropdown-slide-bottom;
          animation-name: dropdown-slide-bottom;
  -webkit-animation-duration: 0.15s;
          animation-duration: 0.15s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-delay: 0.05s;
          animation-delay: 0.05s;
  display: block; }

.show.dropup > .dropdown-menu,
.dropdown-menu.open.dropup > .dropdown-menu {
  -webkit-animation-name: dropdown-slide-top;
          animation-name: dropdown-slide-top; }

.dropdown .dropdown-toggle {
  position: relative; }
  .dropdown .dropdown-toggle:after {
    margin-top: -0.08rem;
    color: #d2d9e5;
    transition: color 0.2s ease-in-out; }
  .dropdown .dropdown-toggle > i {
    color: #d2d9e5;
    transition: color 0.2s ease-in-out; }

.dropdown:hover .dropdown-toggle > i, .dropdown.show .dropdown-toggle > i {
  color: #b8beca; }

.dropdown:hover .dropdown-toggle:after, .dropdown.show .dropdown-toggle:after {
  color: #b8beca; }

@-webkit-keyframes dropdown-slide-left {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes dropdown-slide-left {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@-webkit-keyframes dropdown-slide-bottom {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes dropdown-slide-bottom {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -5px, 0);
            transform: translate3d(0, -5px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@-webkit-keyframes dropdown-slide-top {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes dropdown-slide-top {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 5px, 0);
            transform: translate3d(0, 5px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

/* SELECTBOXES */
select {
  -webkit-appearance: none;
          appearance: none; }

select.form-control {
  color: #74708d;
  height: auto;
  background: #fff center right no-repeat url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAFCAYAAABB9hwOAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA25pVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpiNWZkMzNlMC0zNTcxLTI4NDgtYjA3NC01ZTRhN2RjMWVmNjEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6ODZDNDdFRTkxRTJBMTFFNjg0MUM5MTMwMjYwRDYwRDkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6ODZDNDdFRTgxRTJBMTFFNjg0MUM5MTMwMjYwRDYwRDkiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6RTUxRUI3MDZEQjk4MTFFNUI1NDA5QTcyNTlFQzRERTYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6RTUxRUI3MDdEQjk4MTFFNUI1NDA5QTcyNTlFQzRERTYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz69wtu7AAAAe0lEQVR42mLce+zSOVFhYUMGNHDv4cOd/q6WHgxkAqbvP77H/P339zey4Nfv3z7ceXA/hoECwCQnLXPtw8eP05EFHz15WuRm7/CGIoNBhLCgUPnPX79egdgv37w+qKmqOp+BQsAEpX8wMTFm/fnz5/P/f//DGagAAAIMAKIuMR+q/rU9AAAAAElFTkSuQmCC"); }

select.form-control[multiple] {
  background: #fff; }

select.form-control:not([size]):not([multiple]) {
  height: auto; }

/* BADGES */
.badge {
  font-weight: normal;
  background: #fff;
  color: #6a7a84; }
  .badge.badge-default {
    background: #acb7bf;
    color: #fff; }
  .badge.badge-primary {
    background: #0190fe;
    color: #fff; }
  .badge.badge-secondary {
    background: #6a7a84;
    color: #fff; }
  .badge.badge-success {
    background: #46be8a;
    color: #fff; }
  .badge.badge-info {
    background: #0887c9;
    color: #fff; }
  .badge.badge-danger {
    background: #fb434a;
    color: #fff; }
  .badge.badge-warning {
    background: #f39834;
    color: #fff; }
  .badge.badge-processing {
    background: #0887c9;
    color: #fff; }

.btn .badge {
  top: 1px; }

/* CAROUSEL */
.carousel-indicators li {
  background: #eef0f4;
  opacity: 0.5; }
  .carousel-indicators li.active {
    opacity: 1;
    background: #eef0f4; }

/* COLLAPSE */
.accordion .card {
  border-radius: 0;
  margin-bottom: 0;
  border-bottom-width: 0; }
  .accordion .card:first-child {
    border-radius: 5px 5px 0 0; }
  .accordion .card:last-child {
    border-radius: 0 0 5px 5px;
    border-bottom-width: 1px; }
  .accordion .card .card-header {
    cursor: pointer; }
    .accordion .card .card-header .card-title {
      margin-bottom: 0; }
    .accordion .card .card-header.collapsed {
      border: none; }

.accordion.accordion-margin-bottom .card {
  border-radius: 5px;
  margin-bottom: 1.15rem;
  border-bottom-width: 1px; }

.accordion .accordion-indicator {
  padding-top: 1px;
  color: #b8beca; }
  .accordion .accordion-indicator .plus {
    display: none; }
  .accordion .accordion-indicator .minus {
    display: inline; }

.accordion .collapsed .accordion-indicator .plus {
  display: inline; }

.accordion .collapsed .accordion-indicator .minus {
  display: none; }

/* MODAL */
.modal .modal-content {
  border: none; }

.modal .modal-header .close {
  outline: none !important;
  width: 30px;
  height: 30px;
  display: inline-block;
  line-height: 30px;
  text-align: center; }
  .modal .modal-header .close span {
    position: relative;
    top: 3px; }

.modal.modal-size-small {
  padding-left: 10px;
  padding-right: 10px; }
  .modal.modal-size-small .modal-dialog {
    max-width: 300px;
    width: auto; }
    @media (max-width: 575px) {
      .modal.modal-size-small .modal-dialog {
        max-width: none; } }

.modal.modal-size-large {
  padding-left: 10px;
  padding-right: 10px; }
  .modal.modal-size-large .modal-dialog {
    max-width: 980px;
    width: auto; }

.modal-backdrop {
  background: #0e0b20; }
  .modal-backdrop.in {
    opacity: 0.3; }

.modal-body {
  padding: 1.53rem 3.84rem;
  min-width: 69.23rem; }
  @media (max-width: 991px) {
    .modal-body {
      min-width: 0; } }

/* ALERTS */
.alert {
  border: none;
  font-weight: normal;
  color: #fff; }
  .alert .close {
    color: #fff;
    opacity: 0.5;
    outline: none !important; }
    .alert .close:hover {
      opacity: 1; }
  .alert a,
  .alert .alert-link {
    font-weight: normal;
    color: #fff;
    opacity: 0.7;
    transition: color 0.2s ease-in-out; }
    .alert a:hover,
    .alert .alert-link:hover {
      opacity: 1;
      color: #fff; }
  .alert.alert-default {
    background: #acb7bf; }
  .alert.alert-primary {
    background: #0190fe; }
  .alert.alert-secondary {
    background: #6a7a84; }
  .alert.alert-success {
    background: #46be8a; }
  .alert.alert-info {
    background: #0887c9; }
  .alert.alert-warning {
    background: #f39834; }
  .alert.alert-danger {
    background: #fb434a; }

/* PAGINATION */
.pagination .page-link {
  border-color: #e4e9f0;
  color: #74708d;
  outline: none;
  margin-bottom: 0.76rem; }
  .pagination .page-link:hover, .pagination .page-link:focus {
    background: #acb7bf;
    color: #fff;
    border-color: #acb7bf; }

.pagination .page-item.disabled .page-link {
  background: #f2f4f8; }

.pagination .page-item.active .page-link {
  background: #0190fe;
  border-color: #0190fe; }

.pager li > a {
  border-color: #b8beca;
  color: #393749;
  outline: none;
  border-radius: 3px; }
  .pager li > a:hover, .pager li > a:focus {
    background: #acb7bf;
    color: #fff;
    border-color: #acb7bf; }

/* TABS */
.nav-tabs-horizontal .nav-tabs {
  border-bottom: 1px solid #e4e9f0; }
  .nav-tabs-horizontal .nav-tabs .nav-item {
    margin-bottom: -2px;
    cursor: pointer; }
    .nav-tabs-horizontal .nav-tabs .nav-item .nav-link {
      border: none;
      border-bottom: 3px solid transparent; }
      .nav-tabs-horizontal .nav-tabs .nav-item .nav-link.active, .nav-tabs-horizontal .nav-tabs .nav-item .nav-link:focus {
        border-bottom-color: #0190fe !important; }
      .nav-tabs-horizontal .nav-tabs .nav-item .nav-link:hover {
        border-bottom-color: #b8beca; }

.nav-tabs-vertical:after {
  clear: both;
  content: '';
  display: block; }

.nav-tabs-vertical .nav-tabs {
  border-bottom: none;
  border-right: 1px solid #e4e9f0;
  float: left;
  margin-right: 30px;
  display: block; }
  .nav-tabs-vertical .nav-tabs .nav-item {
    margin: 0 -2px 1px 0;
    float: none;
    cursor: pointer; }
    .nav-tabs-vertical .nav-tabs .nav-item .nav-link {
      border: none;
      border-right: 3px solid transparent;
      padding-left: 0;
      border-radius: 0; }
      .nav-tabs-vertical .nav-tabs .nav-item .nav-link.active, .nav-tabs-vertical .nav-tabs .nav-item .nav-link:focus {
        border-right-color: #0190fe !important; }
      .nav-tabs-vertical .nav-tabs .nav-item .nav-link:hover {
        border-right-color: #b8beca; }

/* TOOLTIPS & POPOVERS */
.popover {
  border-color: #d2d9e5; }
  .popover .popover-inner {
    border-width: 0.61rem; }
    .popover .popover-inner:after {
      border-width: 0.53rem; }
  .popover.left:before, .popover.bs-tether-element-attached-left:before {
    border-right-color: #d2d9e5; }
  .popover.right:before, .popover.bs-tether-element-attached-right:before {
    border-left-color: #d2d9e5; }
  .popover.top:before, .popover.bs-tether-element-attached-top:before {
    border-bottom-color: #d2d9e5; }
  .popover.bottom:before, .popover.bs-tether-element-attached-bottom:before {
    border-top-color: #d2d9e5; }
  .popover .popover-title {
    font-weight: 600;
    background: #f2f4f8;
    margin: -1px;
    padding: 0.61rem 1.15rem;
    border-radius: 3px 3px 0 0; }

.tooltip .tooltip-inner {
  background: #222034;
  padding: 0.3rem 1.15rem;
  color: #fff;
  font-size: 1rem; }

.tooltip.left .tooltip-inner:before,
.tooltip.bs-tether-element-attached-left .tooltip-inner:before {
  border-right-color: #222034; }

.tooltip.right .tooltip-inner:before,
.tooltip.bs-tether-element-attached-right .tooltip-inner:before {
  border-left-color: #222034; }

.tooltip.top .tooltip-inner:before,
.tooltip.bs-tether-element-attached-top .tooltip-inner:before {
  border-bottom-color: #222034; }

.tooltip.bottom .tooltip-inner:before,
.tooltip.bs-tether-element-attached-bottom .tooltip-inner:before {
  border-top-color: #222034; }

.chartist-tooltip {
  position: absolute;
  display: none;
  opacity: 0;
  min-width: 5em;
  padding: 0.5em;
  background: #001529;
  color: #fff;
  font-weight: 700;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  transition: opacity 0.2s linear;
  border-radius: 2px; }

.chartist-tooltip:before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -7px;
  border: 7px solid transparent;
  border-top-color: #001529; }

.chartist-tooltip.tooltip-show {
  display: inline-block;
  opacity: 1; }

.ct-area,
.ct-line {
  pointer-events: none; }

.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.75rem;
  line-height: 1; }

.ct-chart {
  position: relative; }

.ct-tooltip {
  position: absolute;
  display: inline-block;
  min-width: 5em;
  border-radius: 5px;
  padding: 4px 10px;
  background: #383838;
  color: #fff;
  text-align: center;
  pointer-events: none;
  z-index: 100;
  -webkit-transform: translateY(13px);
          transform: translateY(13px);
  transition: opacity 0.2s linear; }

.ct-tooltip:before {
  position: absolute;
  bottom: -14px;
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  pointer-events: none;
  border-color: rgba(251, 249, 228, 0);
  border-top-color: #383838;
  border-width: 7px;
  margin-left: -8px; }

.ct-tooltip.hide {
  display: block;
  opacity: 0;
  visibility: hidden; }

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
  display: block;
  display: flex; }

.ct-label.ct-horizontal.ct-start {
  align-items: flex-end;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-label.ct-horizontal.ct-end {
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-label.ct-vertical.ct-start {
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end; }

.ct-label.ct-vertical.ct-end {
  align-items: flex-end;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-chart-bar .ct-label.ct-horizontal.ct-start {
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  text-anchor: start; }

.ct-chart-bar .ct-label.ct-horizontal.ct-end {
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  text-anchor: start; }

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-start {
  align-items: flex-end;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-end {
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start; }

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-start {
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end; }

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-end {
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  text-anchor: end; }

.ct-grid {
  stroke: rgba(0, 0, 0, 0.2);
  stroke-width: 1px;
  stroke-dasharray: 2px; }

.ct-point {
  stroke-width: 10px;
  stroke-linecap: round; }

.ct-line {
  fill: none;
  stroke-width: 4px; }

.ct-area {
  stroke: none;
  fill-opacity: 0.1; }

.ct-bar {
  fill: none;
  stroke-width: 10px; }

.ct-slice-donut {
  fill: none;
  stroke-width: 60px; }

.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
  stroke: #0190fe; }

.ct-series-a .ct-slice-pie,
.ct-series-a .ct-area {
  fill: #0190fe; }

.ct-series-b .ct-point,
.ct-series-b .ct-line,
.ct-series-b .ct-bar,
.ct-series-b .ct-slice-donut {
  stroke: #46be8a; }

.ct-series-b .ct-slice-pie,
.ct-series-b .ct-area {
  fill: #46be8a; }

.ct-series-c .ct-point,
.ct-series-c .ct-line,
.ct-series-c .ct-bar,
.ct-series-c .ct-slice-donut {
  stroke: #ff0; }

.ct-series-c .ct-slice-pie,
.ct-series-c .ct-area {
  fill: #ff0; }

.ct-series-d .ct-point,
.ct-series-d .ct-line,
.ct-series-d .ct-bar,
.ct-series-d .ct-slice-donut {
  stroke: #d17905; }

.ct-series-d .ct-slice-pie,
.ct-series-d .ct-area {
  fill: #d17905; }

.ct-series-e .ct-point,
.ct-series-e .ct-line,
.ct-series-e .ct-bar,
.ct-series-e .ct-slice-donut {
  stroke: #453d3f; }

.ct-series-e .ct-slice-pie,
.ct-series-e .ct-area {
  fill: #453d3f; }

.ct-series-f .ct-point,
.ct-series-f .ct-line,
.ct-series-f .ct-bar,
.ct-series-f .ct-slice-donut {
  stroke: #59922b; }

.ct-series-f .ct-slice-pie,
.ct-series-f .ct-area {
  fill: #59922b; }

.ct-series-g .ct-point,
.ct-series-g .ct-line,
.ct-series-g .ct-bar,
.ct-series-g .ct-slice-donut {
  stroke: #0544d3; }

.ct-series-g .ct-slice-pie,
.ct-series-g .ct-area {
  fill: #0544d3; }

.ct-series-h .ct-point,
.ct-series-h .ct-line,
.ct-series-h .ct-bar,
.ct-series-h .ct-slice-donut {
  stroke: #6b0392; }

.ct-series-h .ct-slice-pie,
.ct-series-h .ct-area {
  fill: #6b0392; }

.ct-series-i .ct-point,
.ct-series-i .ct-line,
.ct-series-i .ct-bar,
.ct-series-i .ct-slice-donut {
  stroke: #f05b4f; }

.ct-series-i .ct-slice-pie,
.ct-series-i .ct-area {
  fill: #f05b4f; }

.ct-series-j .ct-point,
.ct-series-j .ct-line,
.ct-series-j .ct-bar,
.ct-series-j .ct-slice-donut {
  stroke: #dda458; }

.ct-series-j .ct-slice-pie,
.ct-series-j .ct-area {
  fill: #dda458; }

.ct-series-k .ct-point,
.ct-series-k .ct-line,
.ct-series-k .ct-bar,
.ct-series-k .ct-slice-donut {
  stroke: #eacf7d; }

.ct-series-k .ct-slice-pie,
.ct-series-k .ct-area {
  fill: #eacf7d; }

.ct-series-l .ct-point,
.ct-series-l .ct-line,
.ct-series-l .ct-bar,
.ct-series-l .ct-slice-donut {
  stroke: #86797d; }

.ct-series-l .ct-slice-pie,
.ct-series-l .ct-area {
  fill: #86797d; }

.ct-series-m .ct-point,
.ct-series-m .ct-line,
.ct-series-m .ct-bar,
.ct-series-m .ct-slice-donut {
  stroke: #b2c326; }

.ct-series-m .ct-slice-pie,
.ct-series-m .ct-area {
  fill: #b2c326; }

.ct-series-n .ct-point,
.ct-series-n .ct-line,
.ct-series-n .ct-bar,
.ct-series-n .ct-slice-donut {
  stroke: #6188e2; }

.ct-series-n .ct-slice-pie,
.ct-series-n .ct-area {
  fill: #6188e2; }

.ct-series-o .ct-point,
.ct-series-o .ct-line,
.ct-series-o .ct-bar,
.ct-series-o .ct-slice-donut {
  stroke: #a748ca; }

.ct-series-o .ct-slice-pie,
.ct-series-o .ct-area {
  fill: #a748ca; }

.ct-square {
  display: block;
  position: relative;
  width: 100%; }

.ct-square:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 100%; }

.ct-square:after {
  content: '';
  display: table;
  clear: both; }

.ct-square > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-minor-second {
  display: block;
  position: relative;
  width: 100%; }

.ct-minor-second:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 93.75%; }

.ct-minor-second:after {
  content: '';
  display: table;
  clear: both; }

.ct-minor-second > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-major-second {
  display: block;
  position: relative;
  width: 100%; }

.ct-major-second:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 88.8888888889%; }

.ct-major-second:after {
  content: '';
  display: table;
  clear: both; }

.ct-major-second > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-minor-third {
  display: block;
  position: relative;
  width: 100%; }

.ct-minor-third:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 83.3333333333%; }

.ct-minor-third:after {
  content: '';
  display: table;
  clear: both; }

.ct-minor-third > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-major-third {
  display: block;
  position: relative;
  width: 100%; }

.ct-major-third:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 80%; }

.ct-major-third:after {
  content: '';
  display: table;
  clear: both; }

.ct-major-third > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-perfect-fourth {
  display: block;
  position: relative;
  width: 100%; }

.ct-perfect-fourth:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 75%; }

.ct-perfect-fourth:after {
  content: '';
  display: table;
  clear: both; }

.ct-perfect-fourth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-perfect-fifth {
  display: block;
  position: relative;
  width: 100%; }

.ct-perfect-fifth:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 66.6666666667%; }

.ct-perfect-fifth:after {
  content: '';
  display: table;
  clear: both; }

.ct-perfect-fifth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-minor-sixth {
  display: block;
  position: relative;
  width: 100%; }

.ct-minor-sixth:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 62.5%; }

.ct-minor-sixth:after {
  content: '';
  display: table;
  clear: both; }

.ct-minor-sixth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-golden-section {
  display: block;
  position: relative;
  width: 100%; }

.ct-golden-section:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 61.804697157%; }

.ct-golden-section:after {
  content: '';
  display: table;
  clear: both; }

.ct-golden-section > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-major-sixth {
  display: block;
  position: relative;
  width: 100%; }

.ct-major-sixth:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 60%; }

.ct-major-sixth:after {
  content: '';
  display: table;
  clear: both; }

.ct-major-sixth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-minor-seventh {
  display: block;
  position: relative;
  width: 100%; }

.ct-minor-seventh:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 56.25%; }

.ct-minor-seventh:after {
  content: '';
  display: table;
  clear: both; }

.ct-minor-seventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-major-seventh {
  display: block;
  position: relative;
  width: 100%; }

.ct-major-seventh:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 53.3333333333%; }

.ct-major-seventh:after {
  content: '';
  display: table;
  clear: both; }

.ct-major-seventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-octave {
  display: block;
  position: relative;
  width: 100%; }

.ct-octave:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 50%; }

.ct-octave:after {
  content: '';
  display: table;
  clear: both; }

.ct-octave > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-major-tenth {
  display: block;
  position: relative;
  width: 100%; }

.ct-major-tenth:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 40%; }

.ct-major-tenth:after {
  content: '';
  display: table;
  clear: both; }

.ct-major-tenth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-major-eleventh {
  display: block;
  position: relative;
  width: 100%; }

.ct-major-eleventh:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 37.5%; }

.ct-major-eleventh:after {
  content: '';
  display: table;
  clear: both; }

.ct-major-eleventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-major-twelfth {
  display: block;
  position: relative;
  width: 100%; }

.ct-major-twelfth:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 33.3333333333%; }

.ct-major-twelfth:after {
  content: '';
  display: table;
  clear: both; }

.ct-major-twelfth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.ct-double-octave {
  display: block;
  position: relative;
  width: 100%; }

.ct-double-octave:before {
  display: block;
  float: left;
  content: '';
  width: 0;
  height: 0;
  padding-bottom: 25%; }

.ct-double-octave:after {
  content: '';
  display: table;
  clear: both; }

.ct-double-octave > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

/*# sourceMappingURL=chartist.css.map */
/*  NPROGRESS */
#nprogress {
  pointer-events: none; }

#nprogress .bar {
  height: 3px;
  background: #0190fe;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%; }

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0, -4px);
  transform: rotate(3deg) translate(0, -4px); }

#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  width: 10.76rem;
  padding: 0.61rem 1.15rem;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #e4e9f0;
  right: auto;
  left: 50%;
  top: 1rem;
  margin-left: -5.62rem; }
  #nprogress .spinner:after {
    content: 'Loading...';
    display: inline-block;
    position: absolute;
    top: 0.53rem;
    left: 3.69rem; }

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: solid 2px transparent;
  border-top-color: #0190fe;
  border-left-color: #0190fe;
  border-radius: 50%;
  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite; }

.nprogress-custom-parent {
  overflow: hidden;
  position: relative; }

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute; }

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

/*  RCDRAWER */
.drawer-handle {
  background: #001529 !important;
  top: 104px; }

.drawer.drawer-open .drawer-handle-icon {
  background: transparent !important; }

.drawer-handle-icon {
  background: #fff !important; }
  .drawer-handle-icon:after, .drawer-handle-icon:before {
    background: #fff !important; }

.drawer .drawer-content {
  overflow: visible;
  background: #001529;
  transition: background 0.3s; }

.drawer.drawer-light .drawer-content {
  background: #fff; }

.drawer-content-wrapper {
  box-shadow: none !important; }

@font-face {
  font-family: 'meeraregular';
  font-style: normal;
  font-weight: 400;
  src: url("https://smc.org.in/downloads/fonts/meera/Meera.woff2?v=7.0") format("woff2"), url("https://smc.org.in/downloads/fonts/meera/Meera.woff?v=7.0") format("woff"), url("https://smc.org.in/downloads/fonts/meera/Meera.ttf?v=7.0") format("truetype");
  unicode-range: 'U+0000-00FF', 'U+0131', 'U+0152-0153', 'U+02C6', 'U+02DA', 'U+02DC', 'U+0D01-0D7F', 'U+200C-U+200D', 'U+25CC'; }

.font {
  font-family: 'meeraregular'; }

.ant-table-content,
.ant-form {
  /*text-transform: capitalize;*/ }

.statusArea .ant-row {
  margin: 0;
  padding: 0 !important; }

.statusArea,
.ant-form-vertical .statusArea .ant-form-item-label {
  text-align: right; }

.ant-form-item-label label {
  white-space: nowrap; }

.ant-form-item {
  margin-bottom: 10px; }

.ant-select-selection--multiple .ant-select-selection__choice {
  display: inline-flex;
  align-items: center; }

.common-input .ant-select-selection--single {
  position: relative;
  cursor: pointer;
  display: inline-flex;
  width: 100%;
  vertical-align: middle; }

.common-input .ant-select-selection-selected-value {
  height: 100%;
  display: inline-flex !important;
  align-items: center; }

.ant-calendar-picker {
  display: block !important; }

.view-order-cards .card {
  height: 100%; }

.edit-wrap {
  margin: 0 0 20px; }

.btn {
  border-radius: 0;
  border: none;
  height: auto;
  padding: 5px 20px;
  font-size: 15px;
  font-weight: 500; }

.highlighted-btn {
  background: #219ee0;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600; }

.user-details-card .card-body {
  padding: 15px 10px; }

.user-details-card p strong {
  margin: 0 3px 0 0; }

.btn-margins {
  margin: 0 10px 0 0; }

.ant-tabs-bar {
  margin: 0; }

.ant-tabs .ant-tabs-large-bar .ant-tabs-tab {
  padding: 16px 16px 6px !important;
  margin: 0 10px 0 0; }

.common-card-body {
  padding-top: 10px; }

.order-single-table {
  border: 1px solid #ddd;
  padding: 15px 15px 0;
  margin: 0 0 15px; }
  .order-single-table .ant-steps-item-title {
    white-space: pre-wrap; }

.order-id {
  background: #f7f7f7;
  padding: 7px 15px 6px;
  display: inline-block; }

.track-btn-wraper {
  text-align: right; }

.track-btn {
  background: #0190fe;
  display: inline-block;
  padding: 6px 15px 4px;
  color: #fff; }

.track-btn:hover {
  background: #0d7fd6; }

.order-single-table-header {
  padding: 0 0 10px; }

.ordered-product-head {
  font-weight: 700; }

.order-single-table-content {
  border-top: 1px solid #ddd;
  padding: 10px 0; }

.ordered-product-img {
  display: flex;
  align-items: center;
  height: 100%; }

.ordered-product-details {
  width: 100%;
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center; }

.ordered-product-price {
  font-weight: 700;
  font-size: 18px;
  display: inline-flex;
  margin: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-end; }

.steps-ordered-product {
  margin: auto;
  padding: 20px 0; }

.thumbnail-area {
  display: inline-block;
  width: 4.28rem;
  height: 4.28rem;
  border: 1px solid #e4e9f0;
  border-radius: 3px;
  background: #fff;
  text-align: center; }
  .thumbnail-area img {
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    vertical-align: middle; }

.badge {
  font-size: 95%;
  text-transform: capitalize; }

.ant-tabs .form-group {
  padding-right: 1px; }

.pull-right > *:nth-child(2) {
  margin-left: 15px; }

.left-spaced {
  width: 20%;
  margin-left: auto;
  margin-bottom: 10px;
  margin-top: 10px; }
  .left-spaced > * {
    margin-right: 10px; }
  .left-spaced > :last-child {
    margin-right: 10px; }

.left-spaced-items {
  margin: 1em 1em 0 auto; }

.spaced {
  display: flex;
  justify-content: space-between;
  width: 15em;
  padding: 20px 0 20px 0; }

.ant-table-row td {
  white-space: normal; }

.capitalize {
  text-transform: capitalize; }

.ant-dropdown-trigger:hover {
  cursor: pointer; }

.list-sale-price {
  display: flex;
  justify-content: space-evenly; }
  .list-sale-price span:nth-child(2) {
    -webkit-text-decoration-line: line-through;
            text-decoration-line: line-through; }

.filter-container {
  padding: 1em;
  border: 1px solid #e4e9f0;
  margin-bottom: 2em;
  box-shadow: 0 0 5px 0 #f2f4f8;
  background: #f2f4f8; }
  .filter-container .filter-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 1em; }
  .filter-container .filter-footer {
    margin-top: 1em; }
    .filter-container .filter-footer > * {
      margin-right: 1em; }

.right-flex,
.tab-extra {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1em;
  margin-top: 10px; }
  .right-flex > *,
  .tab-extra > * {
    margin-right: 10px; }
  .right-flex > :last-child,
  .tab-extra > :last-child {
    margin-right: 10px; }

.ant-descriptions.descr-info {
  padding: 1em 0; }

.ant-descriptions .ant-descriptions-item-content {
  /*text-transform: capitalize;*/ }

.white-space-no-wrap {
  white-space: nowrap; }

.card.order-items-table {
  position: relative; }
  .card.order-items-table .editable-add-btn {
    position: absolute;
    top: 1.53rem;
    right: 1.53rem; }

.product-image-list-select .ant-select-selection.ant-select-selection--single {
  height: 58px; }

.card-body.shipping-address {
  border-right: 1px solid #d2d9e5; }

.tab-extra {
  flex-direction: column; }
  @media (max-width: 767px) {
    .tab-extra {
      flex-direction: row;
      margin: 0; } }

.create-shipment-form .ant-form-item-label label {
  text-align: left; }

.create-shipment-form .ant-row.ant-form-item {
  margin-bottom: 10px; }

.border-right-md,
.border-right-lg {
  border-right: 1px solid #d2d9e5; }

.shipment-details-edit .shipment-edit-dispatched-info .ant-form-item-label {
  text-align: left; }

.shipment-tabs.ant-tabs .ant-tabs-tab .assign-title {
  font-weight: bold;
  font-size: 1.15rem; }

.disabled-faded {
  pointer-events: none;
  opacity: 0.2; }

.ribbon-alone {
  display: block;
  border-left: 0.75em solid #dc3544;
  border-right: 0.75em solid #dc3544;
  border-bottom: 0.75em solid #dc3544;
  border-top: 0.75em solid #dc3544;
  border-left-color: transparent; }
  .ribbon-alone.ribbon-hor {
    display: inline-block;
    margin-left: 0.5em;
    font-size: 0.75em;
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); }

.general-settings-form-wrapper .add-new-shipping-btn {
  display: inline-block; }

.general-settings-form-wrapper .add-new-time-btn {
  display: inline-block; }

.general-settings-form-wrapper .remove-shipping-btn,
.general-settings-form-wrapper .remove-times-btn {
  right: 50%;
  float: right;
  bottom: 5em;
  z-index: 1; }

@media (max-width: 1199px) {
  .steps-ordered-wrapper {
    order: 4; }
  .shipment-details .shipment-details-row {
    max-width: 80%; } }

@media (max-width: 991px) {
  .border-right-lg {
    border: none; }
  .view-order-cards .card {
    height: auto; } }

@media (max-width: 767px) {
  .border-right-md {
    border-right: none; }
  .shipment-details .shipment-details-row {
    max-width: 100%; }
  .create-shipment-form .border-right {
    border: none !important; }
  .attribute-wrap {
    margin: 0 0 15px; }
  .order-head {
    font-size: 14px; }
  .card-body.shipping-address {
    border-right: none; } }

@media (max-width: 575px) {
  .ordered-product-price-wrapper {
    order: 2; }
  .ordered-product-details-wrapper {
    order: 3; }
  .ordered-product-details {
    margin: 5px 0 0;
    border-top: 1px dashed #ddd;
    padding: 3px 0 0; }
  .ant-steps-horizontal.ant-steps-label-horizontal {
    display: block; }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-content {
    min-height: 48px;
    overflow: hidden; }
  .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
    display: block;
    overflow: visible; }
  .ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    margin: 0; }
  .ant-steps-horizontal.ant-steps-label-horizontal
> .ant-steps-item:not(:last-child)
> .ant-steps-item-tail {
    display: block; }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-tail {
    position: absolute;
    top: 0;
    left: 16px;
    width: 1px;
    height: 100%;
    padding: 38px 0 6px; }
  .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small .ant-steps-item-tail {
    position: absolute;
    top: 0;
    left: 12px;
    padding: 30px 0 6px; }
  .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-tail::after {
    width: 1px;
    height: 100%; }
  .ant-steps-item-title::after {
    width: 0 !important;
    height: 0 !important; } }

.auth-switch-link {
  cursor: pointer;
  display: inline; }
  .auth-switch-link:hover {
    text-decoration: underline; }
